import React from 'react';

export enum ModalType {
    EDIT = 'EDIT',
    REMOVE = 'REMOVE',
    DELETE = 'DELETE',
    UPDATE = 'UPDATE',
    ADD = 'ADD',
}

export enum WorkflowTypes {
    SAMPLE_SUBMITTAL = 'SAMPLE_SUBMITTAL',
    SAMPLE_PREP = 'SAMPLE_PREP',
    SAMPLE_ANALYSIS = 'SAMPLE_ANALYSIS',
    VALIDATION_RULES = 'VALIDATION_RULES',
    SYMBOLS = 'SYMBOLS',
    SAMPLE_TYPES = 'SAMPLE_TYPES',
}

export interface SamplingActionProps {
    optionsMap: ModalType[];
    disabledMap: Record<string, boolean>;
    onButtonClick: (e: React.MouseEvent<HTMLButtonElement>, mdoalType: ModalType) => void;
}

export interface ActionButtonProps {
    id: string;
    type: WorkflowTypes;
    stateSetter: Function;
}
